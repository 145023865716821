header{
  position: fixed;
  z-index: 10;
}

main{
  display: flex;
  flex-direction: row;
}

.navbar {
  &-brand{
    background-color: #FAFAFA;
  }
  &-burger {
    margin-right: auto;
    margin-left: 0;
  }
  &-item{
    align-items: center;
    background-color: #FAFAFA;
  }
  &-logo{
    margin-right: 0.5rem;
    border-radius: 50%;
  }
  &-menu{
    background-color: #FAFAFA;
  }
}