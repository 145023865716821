/**
  git-book makrdown theme & dark theme
**/
.markdown-section{
    display:block;
    min-height: 600px;
    word-wrap:break-word;
    color:#333;
    line-height:1.7;
    text-size-adjust:100%;
    -ms-text-size-adjust:100%;
    -webkit-text-size-adjust:100%;
    -moz-text-size-adjust:100% 
}
.markdown-section *{
    box-sizing:border-box;
    -webkit-box-sizing:border-box;
    font-size:inherit 
}
.markdown-section>:first-child{
    margin-top:0!important 
}
.markdown-section>:last-child{
    margin-bottom:0!important 
}
.markdown-section blockquote,.markdown-section code,.markdown-section figure,.markdown-section img,.markdown-section pre,.markdown-section table,.markdown-section tr{
    page-break-inside:avoid 
}
.markdown-section h2,.markdown-section h3,.markdown-section h4,.markdown-section h5,.markdown-section p{
    orphans:3;
    widows:3 
}
.markdown-section h1,.markdown-section h2,.markdown-section h3,.markdown-section h4,.markdown-section h5{
    page-break-after:avoid 
}
.markdown-section b,.markdown-section strong{
    font-weight:700 
}
.markdown-section em{
    font-style:italic 
}
.markdown-section blockquote,.markdown-section dl,.markdown-section p,.markdown-section table,.markdown-section ul{
    list-style-type: disc;
    margin-top:0;
    margin-bottom:.85em 
}
.markdown-section a{
    color:#4183c4;
    text-decoration:none;
    background:0 0 
}
.markdown-section a:active,.markdown-section a:focus,.markdown-section a:hover{
    outline:0;
    text-decoration:underline 
}
.markdown-section img{
    border:0;
    max-width:100% 
}
.markdown-section hr{
    height:4px;
    padding:0;
    margin:1.7em 0;
    overflow:hidden;
    background-color:#e7e7e7;
    border:none 
}
.markdown-section hr:after,.markdown-section hr:before{
    display:table;
    content:" " 
}
.markdown-section hr:after{
    clear:both 
}
.markdown-section h1,.markdown-section h2,.markdown-section h3,.markdown-section h4,.markdown-section h5,.markdown-section h6{
    margin-top:1.275em;
    margin-bottom:.85em;
    font-weight:700 
}
.markdown-section h1{
    font-size:2em 
}
.markdown-section h2{
    font-size:1.75em 
}
.markdown-section h3{
    font-size:1.5em 
}
.markdown-section h4{
    font-size:1.25em 
}
.markdown-section h5{
    font-size:1em 
}
.markdown-section h6{
    font-size:1em;
    color:#777 
}
.markdown-section code,.markdown-section pre{
    font-family:Consolas,"Liberation Mono",Menlo,Courier,monospace;
    direction:ltr;
    margin:0;
    padding:0;
    border:none;
    color:inherit 
}
.markdown-section pre{
    overflow:auto;
    word-wrap:normal;
    margin:0;
    padding:.85em 1em;
    margin-bottom:1.275em;
    background:#f7f7f7 
}
.markdown-section pre>code{
    display:inline;
    max-width:initial;
    padding:0;
    margin:0;
    overflow:initial;
    line-height:inherit;
    font-size:.85em;
    white-space:pre;
    background:0 0 
}
.markdown-section pre>code:after,.markdown-section pre>code:before{
    content:normal 
}
.markdown-section code{
    padding:.2em;
    margin:0;
    font-size:.85em;
    background-color:#f7f7f7 
}
.markdown-section code:after,.markdown-section code:before{
    letter-spacing:-.2em;
    content:"\00a0" 
}
.markdown-section table{
    display:table;
    width:100%;
    border-collapse:collapse;
    border-spacing:0;
    overflow:auto 
}
.markdown-section table td,.markdown-section table th{
    padding:6px 13px;
    border:1px solid #ddd 
}
.markdown-section table tr{
    background-color:#fff;
    border-top:1px solid #ccc 
}
.markdown-section table tr:nth-child(2n){
    background-color:#f8f8f8 
}
.markdown-section table th{
    font-weight:700 
}
.markdown-section ol,.markdown-section ul{
    padding:0;
    margin:0;
    margin-bottom:.85em;
    padding-left:2em 
}
.markdown-section ol ol,.markdown-section ol ul,.markdown-section ul ol,.markdown-section ul ul{
    margin-top:0;
    margin-bottom:0 
}
.markdown-section ol ol{
    list-style-type:lower-roman 
}
.markdown-section blockquote{
    margin:0;
    margin-bottom:.85em;
    padding:0 15px;
    color:#858585;
    border-left:4px solid #e5e5e5 
}
.markdown-section blockquote:first-child{
    margin-top:0 
}
.markdown-section blockquote:last-child{
    margin-bottom:0 
}
.markdown-section dl{
    padding:0 
}
.markdown-section dl dt{
    padding:0;
    margin-top:.85em;
    font-style:italic;
    font-weight:700 
}
.markdown-section dl dd{
    padding:0 .85em;
    margin-bottom:.85em 
}
.markdown-section dd{
    margin-left:0 
}
.color-theme-2{
    color: #bdcadb;
    background: #1c1f2b;
}
.color-theme-2 .markdown-section strong{
    color: #bdcadb;
}
.color-theme-2 .markdown-section {
    color: #bdcadb;
}
.color-theme-2 .markdown-section a {
    color: #3eb1d0;
}
.color-theme-2 .markdown-section h1, .color-theme-2 .markdown-section h2, .color-theme-2 .markdown-section h3, .color-theme-2 .markdown-section h4, .color-theme-2 .markdown-section h5, .color-theme-2 .markdown-section h6 {
    color: #fffffa;
}
.color-theme-2 .markdown-section h1, .color-theme-2 .markdown-section h2 {
    border-color: #373b4e;
}
.color-theme-2 .markdown-section h6 {
    color: #373b4e;
}
.color-theme-2 .markdown-section hr {
    background-color: #373b4e;
}
.color-theme-2 .markdown-section blockquote {
    border-color: #373b4e;
}
.color-theme-2 .markdown-section pre, .color-theme-2 .markdown-section code {
    color: #9dbed8;
    background: #2d3143;
    border-color: #2d3143;
}
.color-theme-2 .markdown-section table th, .color-theme-2 .markdown-section table td {
    color: #b6c2d2;
    border-color: #3b3f54;
}
.color-theme-2 .markdown-section table tr {
    color: #b6c2d2;
    background-color: #2d3143;
    border-color: #3b3f54;
}
.color-theme-2 .markdown-section table tr:nth-child(2n) {
    background-color: #35394b;
}
